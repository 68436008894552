<template>
  <div class="modal-content">
    <div class="modal-header">
      <button @click="$emit('close')" type="button" data-dismiss="modal" aria-label="Close" class="close"
              style="width: 30px; height: 30px; border-radius: 30px; font-size: 30px; background: gray;"><span
        aria-hidden="true">×</span></button>
      <div v-if="hasEmailCoupon">
        <h2 class="modal-title title-coupons lg-cond text-center pt-10"
            v-html="`${$t('registration.thankyou.validation.header')} ${this.name || '?'}`"></h2>
        <h3 class="modal-title title-coupons lg-cond text-center pb-10"
            v-html="$t('registration.thankyou.validation.ready_to_order')"></h3>
        <div class="free-menu-description section-content--free-menu t13 text-black text-center">
          <p v-html="`${$t('registration.thankyou.validation.message1')} <b>${this.email}</b> ${$t('registration.thankyou.validation.message11')}`"></p>
          <p>{{$t('registration.thankyou.validation.message2')}}</p>
          <input type="button" class="button button--primary" @click="seePromotions()" :value="$t('registration.thankyou.button.see_promotions')">
        </div>
      </div>
      <div v-else>
        <h2 class="modal-title title-coupons lg-cond text-center pt-10"
            v-html="`${$t('registration.thankyou.no_validation.header')} ${this.name}`"></h2>
        <h3 class="modal-title title-coupons lg-cond text-center pb-10"
            v-html="$t('registration.thankyou.no_validation.ready_to_order')"></h3>
        <div class="free-menu-description section-content--free-menu t13 text-black text-center">
          <p v-html="$t('registration.thankyou.no_validation.message')"></p>
          <input type="button" class="button button--primary" @click="seePromotions()" :value="$t('registration.thankyou.button.see_promotions')">
          <br />
          <p>{{$t('registration.thankyou.no_validation.free_dessert_coupon')}}</p>
          <p>{{$t('registration.thankyou.no_validation.confirm_activate_account')}}</p>
          <!-- <input type="button" class="button button--dark-red" @click="addAddress()" :value="$t('registration.thankyou.button.add_address')"> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default{
  props: ['email', 'name'],
  mounted () {},
  data () {
    const hasEmailCoupon = process.env.VUE_APP_REGISTRATION_EMAIL_HAS_COUPON == '1'
    return {
      hasEmailCoupon
    }
  },
  methods: {
    seePromotions () {
      this.$emit('close')
      this.$router.push('/order/deals')
    },
    addAddress () {
      this.$emit('close')
      this.$router.push('/profile')
    }
  }
}
</script>

<style>
  .v--modal-box {
    height: auto !important;
    top: 50px !important;
  }
  .modal-content .v--modal {
    min-width: 0px !important;
  }
  .lg-cond {
      /*font-family: 'Sharp Sans', Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif !important;*/
  }
</style>
