<template>
  <div id="page-register">
    <div class="xs-p-20 xs-fill-white">
      <div class="form-section xs-mb-20">
        <div class="form-section__heading"><span>{{$t('sign_in_out_with')}}</span></div>
        <div class="w-full xs-mb-5 mb-10">
          <fb-signin-button
            :params="fbSignInParams"
            @success="onSignInFBSuccess"
            @error="onSignInFBError"
            class="login-fb btn btn-primary xs-full-width" style="margin-bottom: 0 !important;">
            <i class="fa fa-facebook fa-fw mr-5"></i>{{$t('sign_in_fb')}}
          </fb-signin-button>
        </div>
        <div class="w-full">
          <g-signin-button v-if="googleSignInParams.client_id"
                           :params="googleSignInParams"
                           @success="onSignInSuccess"
                           @error="onSignInError"
                           class="login-gg btn btn-danger xs-full-width">
            <div class="w-100" @click="() => {
                eventTrackingLoginStart('google');
                eventTrackingLoginSubmit('google');
              }">
              <i class="fa fa-google fa-fw mr-5"></i>{{$t('sign_in_google')}}
            </div>
          </g-signin-button>
        </div>
      </div>

      <form @submit="checkForm" action="" method="post" novalidate="true">
        <div class="form-section first-section">
          <div class="form-section__heading"><span>{{$t('sign_up_manually')}}</span></div>
          <div class="alert alert-danger" role="alert" v-if="errors.summary.length > 0">
            {{ errors.summary }}
            <span>
              <br />
              <a href="/getmypassword" v-if="errors.error_code === 'CUS-100003'">{{$t('recover_password')}}</a>
            </span>
          </div>

          <!--FIRSTNAME LASTNAME-->
          <!-- <div v-if="$i18n.locale ===$const.LANG_ES">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-field">
                  <div class="xs-flex xs-items-center xs-mb-5">
                    <label class="label label-input xs-mb-0" for="LastName">{{$t('last_name')}}<span class="cl-red">{{ $t('required') }}</span></label>
                    <span v-if="errors.lastName"
                          class="xs-text-right typo-l7 xs-text-red xs-ml-auto error-text">
                                        <span>{{ errors.lastName }}</span>
                    </span>
                  </div>
                  <input id="LastName"
                         ref="lastName"
                         class="input xs-mb-10  "
                         v-bind:class="{ 'has-errors': errors.lastName , 'has-success': errors.lastName === false}"
                         name="lastName"
                         v-model="lastName"
                         v-bind:placeholder="$t('place_holder_last_name')"
                         type="text">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-field">
                  <div class="xs-flex xs-items-center xs-mb-5">
                    <label class="label label-input xs-mb-0" for="FirstName">{{$t('first_name')}}<span class="cl-red">{{ $t('required') }}</span></label>
                    <span v-if="errors.firstName" class="xs-text-right typo-l7 xs-text-red xs-ml-auto error-text">
                                        <span>{{ errors.firstName }}</span>
                    </span>
                  </div>
                  <input id="FirstName" ref="firstName" class="input xs-mb-10  "
                         v-bind:class="{ 'has-errors': errors.firstName , 'has-success': errors.firstName === false}"
                         name="firstName" v-model="firstName" value=""
                         v-bind:placeholder="$t('place_holder_first_name')" type="text">
                </div>
              </div>

            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-field">
                  <div class="xs-flex xs-items-center xs-mb-5">
                    <label class="label label-input xs-mb-0" for="localLastName">{{$t('local_last_name')}}<span
                      class="cl-red">{{ $t('required') }}</span></label>
                    <span v-if="errors.localLastName" class="xs-text-right typo-l7 xs-text-red xs-ml-auto error-text">
                                          <span>{{ errors.localLastName }}</span>
                </span>
                  </div>
                  <input id="localLastName" ref="localLastName" class="input xs-mb-10  "
                         v-bind:class="{ 'has-errors': errors.localLastName , 'has-success': errors.localLastName === false}"
                         name="localLastName" v-model="localLastName" value=""
                         v-bind:placeholder="$t('place_holder_local_last_name')" type="text">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-field">
                  <div class="xs-flex xs-items-center xs-mb-5">
                    <label class="label label-input xs-mb-0" for="localFirstName">{{$t('local_first_name')}}<span
                      class="cl-red">{{ $t('required') }}</span></label>
                    <span v-if="errors.localFirstName" class="xs-text-right typo-l7 xs-text-red xs-ml-auto error-text">
                                          <span>{{ errors.localFirstName }}</span>
                </span>
                  </div>
                  <input id="localFirstName" ref="localFirstName" class="input xs-mb-10  "
                         v-bind:class="{ 'has-errors': errors.localFirstName , 'has-success': errors.localFirstName === false}"
                         name="localFirstName"
                         v-model="localFirstName"
                         v-bind:placeholder="$t('place_holder_local_first_name')"
                         type="text">
                </div>
              </div>
            </div>
          </div> -->
          <div>
            <div class="form-field">
              <div class="xs-flex xs-items-center xs-mb-5">
                <label class="label label-input xs-mb-0" for="FirstName">{{$t('first_name')}}<span class="cl-red">{{ $t('required') }}</span></label>
                <span v-if="errors.firstName" class="xs-text-right typo-l7 xs-text-red xs-ml-auto error-text">
                                        <span>{{ errors.firstName }}</span>
              </span>
              </div>
              <input id="FirstName" ref="firstName" class="input xs-mb-10  "
                     v-bind:class="{ 'has-errors': errors.firstName , 'has-success': errors.firstName === false}"
                     name="firstName" v-model="firstName" value="" v-bind:placeholder="$t('place_holder_first_name')"
                     type="text">
            </div>
            <div class="form-field">
              <div class="xs-flex xs-items-center xs-mb-5">
                <label class="label label-input xs-mb-0" for="LastName">{{$t('last_name')}}<span class="cl-red">{{ $t('required') }}</span></label>
                <span v-if="errors.lastName" class="xs-text-right typo-l7 xs-text-red xs-ml-auto error-text">
                                        <span>{{ errors.lastName }}</span>
              </span>
              </div>
              <input id="LastName" ref="lastName" class="input xs-mb-10  "
                     v-bind:class="{ 'has-errors': errors.lastName , 'has-success': errors.lastName === false}"
                     name="lastName" v-model="lastName" value="" v-bind:placeholder="$t('place_holder_last_name')"
                     type="text">
            </div>
          </div>
          <!--END FIRSTNAME LASTNAME-->

          <!-- <div class="form-field">
            <div class="form-group">
              <label for="gender" class="label-input">{{$t('gender')}}</label>
              <select class="form-control" id="gender" ref="gender" v-model="gender">
                <option value="">{{$t('not_specify')}}</option>
                <option value="M">{{$t('male')}}</option>
                <option value="F">{{$t('female')}}</option>
              </select>
            </div>
          </div> -->

          <div class="form-field">
            <div class="xs-flex xs-items-center xs-mb-5">
              <label class="label label-input xs-mb-0" for="emailAddress">{{$t('email')}}<span class="cl-red">{{ $t('required') }}</span></label>
              <span v-if="errors.emailAddress" class="xs-text-right typo-l7 xs-text-red xs-ml-auto error-text">
                                        <span>{{ errors.emailAddress }}</span>
              </span>
            </div>
            <input id="emailAddress" ref="emailAddress"
                   v-bind:class="{ 'has-errors': errors.emailAddress , 'has-success': errors.emailAddress === false }"
                   name="emailAddress" v-model="emailAddress" class="input xs-mb-10" value="" type="text">
            <!--v-bind:placeholder="$t('place_holder_email')"-->
          </div>

          <div class="form-field">
            <div class="xs-flex xs-items-center xs-mb-5">
              <label class="label label-input xs-mb-0" for="phone">{{$t('phone_number_register')}}<span class="cl-red">{{ $t('required') }}</span></label>
              <span v-if="errors.phone" class="xs-text-right typo-l7 xs-text-red xs-ml-auto error-text">
                                        <span>{{ errors.phone }}</span>
                </span>
            </div>
            <input type="text" id="phone" ref="phone" class="input"
                   :minlength=8
                   :maxlength=10
                   v-bind:class="{ 'has-errors': errors.phone, 'has-success': errors.phone === false }"
                   name="phone"
                   v-model="phone"
                   v-bind:placeholder="$t('place_holder_phone')" />
            <!--v-bind:placeholder="$t('place_holder_phone')"-->
          </div>

          <!-- <div class="form-field">
            <div class="xs-flex xs-items-center xs-mb-5">
              <label class="label label-input xs-mb-0">{{$t('profile.register_news')}}</label>
            </div>
            <label class="xs-mb-30 xs-block">
              <input v-model="receive_notification" class="checkbox" type="checkbox">
              <span>{{$t('profile.register_news_content')}}</span>
            </label>
          </div>
          <div v-if="false" class="form-field">
            <div class="xs-flex xs-items-center xs-mb-5">
              <label class="label label-input xs-mb-0">{{$t('profile.register_html')}}</label>
            </div>
            <label class="xs-mb-30 xs-block">
              <input v-model="receive_html_mail" class="checkbox" type="checkbox">
              <span>{{$t('profile.register_html_content')}}</span>
            </label>
          </div> -->

          <!--BIRTHDAY-->
          <div class="birthday-section" v-if="$i18n.locale ===$const.LANG_ES">
            <div class="form-field ">
              <div class="xs-flex xs-items-center xs-mb-5">
                <label class="label label-input xs-mb-0">{{$t('birth_day')}}<span
                  class="cl-red">{{ $t('required') }}</span></label>
                <span v-if="errors.birthday" class="xs-text-right typo-l7 xs-text-red xs-ml-auto error-text">
                                        <span>{{ errors.birthday }}</span>
              </span>
              </div>
              <div class="row">
                <div class="col-sm-4">
                  <select v-model="birthday.year" class="form-control " ref="birthdayYear">
                    <option disabled value="">{{$t('year')}}</option>
                    <option v-for="item in yearList"
                            v-bind:value="item">
                      {{ item }}
                    </option>
                  </select>
                </div>

                <div class="col-sm-4">
                  <select v-model="birthday.month" class="form-control " ref="birthdayMonth"  v-bind:class="{ 'has-errors': errors.birthday_month}">
                    <option disabled value="">{{$t('month')}}</option>
                    <option v-for="item in 12" v-bind:value="item">
                      {{ item }}
                    </option>
                  </select>
                </div>
                <div class="col-sm-4">
                  <select v-model="birthday.day" class="form-control " ref="birthdayDay" v-bind:class="{ 'has-errors': errors.birthday_day}">
                    <option disabled value="">{{$t('day')}}</option>
                    <option v-for="item in 31" v-bind:value="item">
                      {{ item }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="birthday-section" v-else>
            <div class="form-field ">
              <div class="xs-flex xs-items-center xs-mb-5">
                <label class="label label-input xs-mb-0">{{$t('birth_day')}}<span
                  class="cl-red">{{ $t('required') }}</span></label>
                <span v-if="errors.birthday" class="xs-text-right typo-l7 xs-text-red xs-ml-auto error-text">
                                        <span>{{ errors.birthday }}</span>
              </span>
              </div>
              <div class="row">
                <div class="col-sm-4">
                  <select v-model="birthday.day" class="form-control " ref="birthdayDay">
                    <option disabled value="">{{$t('day')}}</option>
                    <option v-for="item in 31" v-bind:value="item">
                      {{ item }}
                    </option>
                  </select>
                </div>
                <div class="col-sm-4">
                  <select v-model="birthday.month" class="form-control " ref="birthdayMonth">
                    <option disabled value="">{{$t('month')}}</option>
                    <option v-for="item in 12" v-bind:value="item">
                      {{ item }}
                    </option>
                  </select>
                </div>
                <div class="col-sm-4">
                  <select v-model="birthday.year" class="form-control " ref="birthdayYear">
                    <option disabled value="">{{$t('year')}}</option>
                    <option v-for="item in (new Date()).getFullYear() " v-if="item >= $const.LIMIT_BIRTHDAY"
                            v-bind:value="item">
                      {{ item }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <!--END BIRTHDAY-->

          <div class="form-field">
            <div class="xs-flex xs-items-center xs-mb-5">
              <label class="label label-input xs-mb-0" for="password">{{$t('your_pass')}}<span class="cl-red">{{ $t('required') }}</span></label>
              <span v-if="errors.password" class="xs-text-right typo-l7 xs-text-red xs-ml-auto error-text">
                                        <!-- <span>{{ errors.password }}</span> -->
              </span>
            </div>
            <input id="password" ref="password" class="input xs-mb-10  " :minlength=8
                   v-bind:class="{ 'has-errors': errors.password , 'has-success': errors.password === false}"
                   name="password" v-model="password" value="" type="password" autocomplete="password">
            <div class="inline-block w-full" v-if="errors.password">
              <span>{{ $t("validate_password_rule_line1") }}</span><br />
              <span>{{ $t("validate_password_rule_line2") }}</span>
              <ul class="text-red">
                <li>{{ $t("validate_password_rule_line3") }}</li>
                <li>{{ $t("validate_password_rule_line4") }}</li>
                <li>{{ $t("validate_password_rule_line5") }}</li>
                <li>{{ $t("validate_password_rule_line6") }}</li>
              </ul>
            </div>
            <!--v-bind:placeholder="$t('place_holder_password')"-->
          </div>

          <div class="form-field">
            <div class="xs-flex xs-items-center xs-mb-5">
              <label class="label label-input xs-mb-0" for="re_password">{{$t('retype_pass')}}<span class="cl-red">{{ $t('required') }}</span></label>
              <span v-if="errors.re_password" class="xs-text-right typo-l7 xs-text-red xs-ml-auto error-text">
                                        <span>{{ errors.re_password }}</span>
              </span>
            </div>
            <input id="re_password" ref="re_password" class="input xs-mb-10 check-validate"
                   v-bind:class="{ 'has-errors': errors.re_password , 'has-success': errors.re_password === false}"
                   name="re_password" v-model="re_password" value="" type="password" autocomplete="password">
            <!--v-bind:placeholder="$t('place_holder_repassword')"-->
          </div>

          <div class="form-field pt-10 terms">
            <div class="row">
              <div class="col-sm-12 term-box">
                <label class="xs-mb-30 col-sm-1 xs-block checkbox-box" for="marketing">
                  <input name="term" ref="term" v-model="term" id="marketing" class="checkbox" type="checkbox">
                  <span class="xs-flex xs-items-center fw-normal text-black ">
                                        </span>
                </label>
                <span class="col-sm-11 fw-normal text-black ">
                                         {{$t('term_1')}} <a class="underline" href="/terms">{{$t('term_4')}}</a> & <a
                  class="underline" href="/privacy">{{$t('term_2')}}</a> {{$t('term_3')}}
                                    </span>
              </div>
            </div>
            <div class="col-sm-12 xs-flex xs-items-center mb-20 xs-mt-5 xs-mb-5">
                <span v-if="errors.term" class="typo-l7 xs-text-red error-text">
                                        <span>{{ errors.term }}</span>
                </span>
            </div>
          </div>
          <input type="submit" class="button button--primary" :value="$t('register')">

        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import moment from 'moment'
import regisConfirmEmail from '../components/popup/ModalThankRegister.vue'
import GSignInButton from 'vue-google-signin-button'
import FBSignInButton from 'vue-facebook-signin-button'
import main from '../main.js'

var currentYear = new Date().getFullYear()
var regexPassword = /^[a-zA-Z0-9!@_\-\*\?]{8,}$/

const PASSWORD_MIN = 8

Vue.use(FBSignInButton)
Vue.use(GSignInButton)

export default {
  components: {
    Vue
  },
  beforeCreate () {
    const gapi = document.createElement('script')
    gapi.setAttribute('src', 'https://apis.google.com/js/api:client.js')
    document.head.appendChild(gapi)
    window.fbAsyncInit = function ($fbId) {
      FB.init({
        appId: $fbId.app_id,
        cookie: true, // enable cookies to allow the server to access the session
        xfbml: true, // parse social plugins on this page
        version: 'v3.1' // use graph api version 2.8
      })
    };
    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) return
      js = d.createElement(s)
      js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      js.rel = 'preconnect'
      fjs.parentNode.insertBefore(js, fjs)
    }(document, 'script', 'facebook-jssdk'))
  },
  beforeMount: function () {
    // this.$user.init(this)
    // if (this.$user.isAuthenticated(this)) {
    //   this.$router.push('home')
    // }
  },
  watch: {
    emailAddress: function (newValue, oldValue) {
      if (this.emailAddress && !this.sendRegisterStart) {
        this.eventTrackingRegisterStart()
        this.sendRegisterStart = true
      }
      if (!this.emailAddress) {
        this.errors.emailAddress = this.$t('required_display')
      } else if (!this.validEmail(this.emailAddress)) {
        this.errors.emailAddress = this.$t('validate_invalid_email')
      } else {
        this.errors.emailAddress = false
      }
    },
    password: function (newValue, oldValue) {
      if (!newValue) {
        this.errors.password = this.$t('required_display')
      } else if (newValue.length < PASSWORD_MIN || !regexPassword.test(newValue)) {
        this.errors.password = true // this.$t('validate_invalid_password')
      } else {
        this.errors.password = false
      }
    },
    re_password: function (newValue, oldValue) {
      if (!newValue) {
        this.errors.re_password = this.$t('required_display')
      } else if (newValue.length < PASSWORD_MIN) {
        this.errors.re_password = this.$t('validate_invalid_password')
      } else if (this.re_password !== this.password) {
        this.errors.re_password = this.$t('validate_repassword')
      } else {
        this.errors.re_password = false
      }
    },
    firstName: function (newValue, oldValue) {
      if (!this.firstName) {
        this.errors.firstName = this.$t('required_display')
      } else {
        this.errors.firstName = false
      }

      if (this.$i18n.locale === this.$const.LANG_EN) {
        this.localFirstName = newValue
      }
    },
    lastName: function (newValue, oldValue) {
      if (!this.lastName) {
        this.errors.lastName = this.$t('required_display')
      } else {
        this.errors.lastName = false
      }

      if (this.$i18n.locale === this.$const.LANG_EN) {
        this.localLastName = newValue
      }
    },

    localFirstName: function (newValue, oldValue) {
      if (this.$i18n.locale === this.$const.LANG_ES) {
        if (!this.localFirstName) {
          this.errors.localFirstName = this.$t('required_display')
        } else {
          this.errors.localFirstName = false
        }
      }
    },
    localLastName: function (newValue, oldValue) {
      if (this.$i18n.locale === this.$const.LANG_ES) {
        if (!this.localLastName) {
          this.errors.localLastName = this.$t('required_display')
        } else {
          this.errors.localLastName = false
        }
      }
    },
    phone: function (newValue, oldValue) {
      let numbers = /^[-+]?[0-9]+$/
      let message = ''

      if (newValue && !newValue.match(numbers)) {
        this.$set(this, 'phone', oldValue)
        return
      }

      if (!this.phone) {
        message = this.$t('required_display')
      } else if (this.phone.length < this.$const.PHONE_MIN_LENGTH || this.phone.length > this.$const.PHONE_MAX_LENGTH) {
        message = this.$t('validate_invalid_phone')
      } else {
        message = false
      }

      this.errors.phone = message
    },

    term: function (newValue, oldValue) {
      this.errors.term = ''
      if (!this.term) {
        this.errors.term = this.$t('term')
      }
    }
  },
  computed: {
    yearList () {
      var year = new Date().getFullYear()
      var list = []
      for (var i = year; i > this.$const.LIMIT_BIRTHDAY; i--) {
        list.push(i)
      }

      return list
    }
  },
  data () {
    return {
      sendRegisterStart: false,
      selected: '',
      hasEmailCoupon: '0',
      // start_year: (new Date()).getFullYear(),
      // end_year: 1900,
      birthday: {
        year: '',
        month: '',
        day: ''
      },
      receive_notification: 1,
      receive_html_mail: 1,
      term: '',
      firstName: '',
      lastName: '',
      localFirstName: '',
      localLastName: '',
      phone: '',
      emailAddress: '',
      re_password: '',
      password: '',
      errors: {
        'birthday': '',
        'birthday_month': '',
        'birthday_day': '',
        'emailAddress': '',
        'phone': '',
        'firstName': '',
        're_password': '',
        'password': '',
        'lastName': '',
        'localFirstName': '',
        'localLastName': '',
        'summary': '',
      },
      gender: '',
      googleSignInParams: {
        client_id: ''
      },
      fbSignInParams: {
        scope: 'email',
        return_scopes: true
      },
      app_secret: '',
      app_id: ''
    }
  },
  mounted () {
    this.hasEmailCoupon = process.env.VUE_APP_REGISTRATION_EMAIL_HAS_COUPON
    this.birthday.year = currentYear - this.$const.DEFAULT_SELECTED_BIRTH_YEAR
    this.ggData()
    this.fbData()
    this.eventTrackingScreenLoad(this, {screen: {'name': 'account.register', 'type': 'account'}})
    let formInfo = {
      id: 'register-form',
      name: 'register-form',
      category: 'register',
      url: window.location.href
    }
    this.eventTrackingFormLoad(formInfo)
  },
  methods: {
    checkForm: function (e) {
      e.preventDefault()
      let flag = false

      this.errors = {
        'birthday': '',
        'emailAddress': '',
        'phone': '',
        'firstName': '',
        'lastName': '',
        're_password': '',
        'password': '',
        'localFirstName': '',
        'localLastName': '',
        'birthday_month': '',
        'birthday_day': '',
        'summary': ''
      }

      if (!this.firstName) {
        this.errors.firstName = this.$t('required_display')

        this.$refs.firstName.focus()
        flag = true
      } else {
        this.errors.firstName = false
      }

      if (!this.lastName) {
        this.errors.lastName = this.$t('required_display')
        if (!flag) {
          this.$refs.lastName.focus()
        }
        flag = true
      } else {
        this.errors.lastName = false
      }

      // if (this.$i18n.locale === this.$const.LANG_ES) {
      //   if (!this.localFirstName) {
      //     this.errors.localFirstName = this.$t('required_display')
      //     if (!flag) {
      //       this.$refs.localFirstName.focus()
      //     }
      //     flag = true
      //   } else {
      //     this.errors.localFirstName = false
      //   }

      //   if (this.$i18n.locale === this.$const.LANG_ES) {
      //     if (!this.localLastName) {
      //       this.errors.localLastName = this.$t('required_display')
      //       if (!flag) {
      //         this.$refs.localLastName.focus()
      //       }
      //       flag = true
      //     } else {
      //       this.errors.localLastName = false
      //     }
      //   }
      // }

      if (!this.emailAddress) {
        this.errors.emailAddress = this.$t('required_display')
        if (!flag) {
          this.$refs.emailAddress.focus()
        }
        flag = true
      } else if (!this.validEmail(this.emailAddress)) {
        this.errors.emailAddress = this.$t('validate_invalid_email')
        if (!flag) {
          this.$refs.emailAddress.focus()
        }
        flag = true
      } else {
        this.errors.emailAddress = false
      }
      if (!this.phone) {
        this.errors.phone = this.$t('required_display')
        if (!flag) {
          this.$refs.phone.focus()
        }
        flag = true
      } else if (this.phone.length < 6 || this.phone.length > 11) {
        this.errors.phone = this.$t('validate_invalid_phone')
        if (!flag) {
          this.$refs.phone.focus()
        }
        flag = true
      } else {
        this.errors.phone = false
      }
      if (!this.password) {
        this.errors.password = this.$t('required_display')
        if (!flag) {
          this.$refs.password.focus()
        }
        flag = true
      } else if (this.password.length < PASSWORD_MIN) {
        if (!flag) {
          this.$refs.password.focus()
        }
        this.errors.password = this.$t('validate_invalid_password')
        flag = true
      } else {
        this.errors.password = false
      }

      if (!this.re_password) {
        this.errors.re_password = this.$t('required_display')
        if (!flag) {
          this.$refs.re_password.focus()
        }
        flag = true
      } else if (this.re_password.length < PASSWORD_MIN) {
        if (!flag) {
          this.$refs.re_password.focus()
        }
        this.errors.re_password = this.$t('validate_invalid_password')
        flag = true
      } else if (this.re_password !== this.password) {
        this.errors.re_password = this.$t('validate_repassword')
        if (!flag) {
          this.$refs.re_password.focus()
        }
        flag = true
      } else {
        this.errors.re_password = false
      }

      if (this.birthday.day === '' || this.birthday.month == '' || this.birthday.year == '') {
        this.errors.birthday = this.$t('required_display')
        if (this.birthday.day === '') {
          this.errors.birthday_day = true
        }
        if (this.birthday.month === '') {
          this.errors.birthday_month = true
        }
        if (!flag) {
          if (this.birthday.day === '') {
            this.$refs.birthdayDay.focus()
          } else if (this.birthday.month === '') {
            this.$refs.birthdayMonth.focus()
          } else if (this.birthday.year === '') {
            this.$refs.birthdayYear.focus()
          }
        }
        flag = true
      } else if (!this.validBirthday(this.birthday)) {
        this.errors.birthday = this.$t('validate_invalid_birthday')
        if (!flag) {
          this.$refs.birthday.focus()
        }
        flag = true
      }
      if (!this.term) {
        this.errors.term = this.$t('validate_term')
        if (!flag) {
          this.$refs.term.focus()
        }
        flag = true
      }

      if (flag) {
        return false
      }
      this.register()
    },
    validEmail: function (email) {
      // let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      let re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      return re.test(email)
    },
    validBirthday: function (day) {
      let d = new Date(day.year, day.month - 1, day.day)
      return d instanceof Date && !isNaN(d) && (d.getMonth() + 1) === day.month
    },
    register () {
      let vm = this
      let data = {
        'client': 131,
        'email': this.emailAddress,
        'first_name': this.firstName,
        'last_name': this.lastName,
        'password': this.password,
        'phone': this.phone,
        'birthdate': moment(String(this.birthday.year + '/' + this.birthday.month + '/' + this.birthday.day)).format('YYYY/MM/DD'),
        'domain': process.env.BASE_URL,
        'has_email_coupon': this.hasEmailCoupon
      }
      data.receive_notification = this.receive_notification ? 1 : 0
      data.receive_html_mail = this.receive_html_mail ? 1 : 0
      this.eventTrackingFormSubmit({id: 'register-form'})
      this.axios.apiCustomer.register(data, (response) => {
        this.eventTrackingFormSuccess({id: 'register-form'}, 'email')
        vm.showpopup(data)
      }, function (error) {
        error = error.error
        let formInfo = {
          id: 'register-form',
          'error': {
            'code': error.code,
            'type': 'validation',
            'error': error.message
          }
        }
        vm.errors.summary = error.message
        vm.errors.error_code = error.code
        vm.eventTrackingFormError(formInfo)
      })
    },
    showpopup (data) {
      this.$modal.show(regisConfirmEmail, {
        email: data.email,
        name: `${data.first_name} ${data.last_name}`
      }, {'class': 'modal-regis'})
      this.$router.push('/')
    },
    changePhone1 (event) {
      var value = event.target.value
      var newChar = event.data
      var numbers = /^[-+]?[0-9]+$/
      if (newChar && !newChar.match(numbers)) {
        this.phone = event.target.value.slice(0, -1)
        event.target.value = this.phone
      } else {
        this.phone = value
        event.target.value = this.phone
      }

      var input = document.querySelector('#' + event.target.id)
      input.data = this.phone
    },
    convertKana (text, type) {
      let vm = this
      type = vm.lodash.toLower(type)

      this.convertToKanaName(text, function (err, res) {
        if (type === 'lastname') {
          vm.localLastName = res
        } else if (type === 'firstname') {
          vm.localFirstName = res
        }
      })
    },

    /** ***LOGIN FB GG*****/
    onSignInSuccess (googleUser) {
      let response = googleUser.getAuthResponse('id_token')
      let profile = googleUser.getBasicProfile() // etc etc
      let params = {
        social_id: profile.getId(),
        email: profile.getEmail(),
        first_name: profile.getFamilyName(),
        last_name: profile.getGivenName(),
        social: this.$const.GOOGLE_LOGIN,
        social_token: response.id_token
      }

      this.callbackLogin(params, 'google')
    },
    onSignInError (error) {
      console.error(error, 'ERROR OCCURRED WHILE SSO')
      main.$notify({
        type: 'danger',
        title: this.$t('customer.login_registration.notice_title'),
        text: this.$t('customer.login_registration.notice_1')
      })
    },
    onSignInFBSuccess (response) {
      let accessToken = response.authResponse.accessToken
      // let appsecret_proof = CryptoJS.HmacSHA256(accessToken, this.app_secret).toString(CryptoJS.enc.Hex);
      FB.api('/me', 'get', {fields: 'id,name,first_name,last_name,middle_name,gender,email,birthday,picture'}, dude => {
        if (typeof dude.email !== 'undefined') {
          let params = {
            social_id: dude.id,
            email: dude.email,
            first_name: dude.first_name,
            last_name: dude.last_name,
            social: this.$const.FACEBOOK_LOGIN,
            social_token: accessToken
            // appsecret_proof: appsecret_proof
          }

          this.callbackLogin(params, 'facebook')
        } else {
          this.$router.push({'name': 'register'})
        }
      })
    },
    onSignInFBError (error) {
      console.error(error, 'ERROR OCCURRED WHILE SSO')
      main.$notify({
        type: 'danger',
        title: this.$t('customer.login_registration.notice_title'),
        text: this.$t('customer.login_registration.notice_2')
      })
    },
    callbackLogin: function (params, method) {
      let user = params
      user.localFirstName = params.first_name
      user.localLastName = params.last_name
      var vm = this
      this.$auth.login({
        data: user,
        success: function (response) {
          if (response.data.status) {
            vm.eventTrackingLoginSuccess(vm, method)
            vm.eventTrackingFormSuccess({id: 'additional-details-hut-rewards'}, method)
            main.$notify({
              type: 'danger',
              title: this.$t('customer.login_registration.notice_title'),
              text: this.$t('customer.login_registration.notice_success')
            })
            this.$root.$emit('onProcessChangeCategory')
          } else {
            this.errors.emailAddress = response.data.error.msg
          }
        },
        error: function (data) {
          main.$notify({
            type: 'danger',
            title: 'Error',
            text: 'Login failed. Please check it again!'
          })
        }
      })
    },
    ggData: function () {
      let param = {scope: 'social.gg'}
      var vm = this
      this.axios.apiTenant.getSocialByClient(param, function (response) {
        vm.googleSignInParams.client_id = response.data.web
      }, function (error) {
        console.log(`error: ${error}`)
      })
      // this.axios.get('product-tenant/config?scope=social.gg')
      //   .then(respones => {
      //     this.googleSignInParams.client_id = respones.data
      //   }).catch(error => {
      //   console.log(error)
      // })
    },
    fbData: function () {
      var vm = this
      let param = {scope: 'social.fb'}
      this.axios.apiTenant.getSocialByClient(param, function (response) {
        vm.app_secret = response.app_secret

        window.fbAsyncInit(response.data)
      }, function (error) {
        console.log(`error: ${error}`)
      })
      // this.axios.get('product-tenant/config?scope=social.fb')
      //   .then(respones => {
      //     window.fbAsyncInit(respones.data)
      //   }).catch(error => {
      //   console.log(error)
      // })
    }
    /** ***END GG FB ****/
  }
}

</script>
